<template>
  <div>
    <div class='flex flex-row items-stretch px-2 py-2 w-full sticky top-0 z-30 h-16'>
      <input 
        class='form-input flex-grow rounded-r-0 border-r-0' 
        type='search' 
        placeholder='search' 
        v-model='searchString' />
      <button 
        class='block p-2 border flex-shrink-0 rounded-r'
        @click='toggleFilterOptions'>
        <adjustments-icon class='h-5' style='transform: rotate(90deg);' />
      </button>
      <div v-if='showFilterOptions'
        class='absolute w-full border-t left-0 bg-white z-50 flex flex-row items-stretch px-2 py-3 shadow-md gap-x-1' 
        style='top:100%;'>
        <label class='flex flex-row justify-start items-center gap-x-1 whitespace-no-wrap'>
          <input type='checkbox'
            class='form-checkbox'
            name='includeSpac'
            v-model='includeSpac'>
          <span class='text-xs uppercase'>스팩 포합</span>
        </label>
      </div>
    </div>
    <div class='ipo-table-container'>
      <table class='ipo-table border-l border-r text-xs'>
        <thead class='sticky top-0 bg-white'>
          <tr>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-t' rowspan='2'>
              <div class='flex flex-row justify-start items-center gap-x-1'>
                <span>
                  종목명
                </span>
                <span class='font-normal'>({{ filterdIposLength }})</span>
              </div>
            </th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l border-t' rowspan='2'>주간사</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l border-t' rowspan='2'>시장구분</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l border-t' rowspan='2'>섹터</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l border-t text-center' colspan='4'>수요예측정보</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l border-t text-center' colspan='4'>주식수</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l border-t text-center' colspan='7'>수요예측결과</th>
          </tr>
          <tr class='text-left'>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l'>수요예측일</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>상장일</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>희망공모가액</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>희망공모시총</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>공모금액</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>총공모주식수</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>유통가능주식수</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>총발행주식수</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>유통가능비율</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>수요예측참여가</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>확정공모가</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>확정공모시총</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>기관경쟁률</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>의무보유확약</th>
            <th class='px-1 py-1 bg-gray-100 whitespace-no-wrap border-b border-l text-right'>청약경쟁률</th>
          </tr>
        </thead>
        <tbody v-for='ipo in filteredIpos' 
          :key='`ipo-information-id-${ipo.id}`'>
          <ipo-information-row :ipo='ipo'
            :style='selectedRow(ipo.id)'
            @select-row='selectRow' />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { AdjustmentsIcon } from '@vue-hero-icons/outline'
import IpoInformationRow from '@/components/mobile/IpoInformationRow.vue'

export default {
  name: 'IpoInformation',
  components: {
    IpoInformationRow,
    AdjustmentsIcon,
  },
  data () {
    return {
      selectedIpoId: 0,
      includeSpac: false,
      showFilterOptions: false,
      searchString: '',
    }
  },
  computed: {
    ...mapFields('ipos', [
      'ipos',
    ]),
    filteredIpos () {
      return this.ipos.filter(ipo => this.ipoIncludedInFilters(ipo))
    },
    filterdIposLength () {
      return this.filteredIpos.length
    },
  },
  methods: {
    ...mapActions('ipos', [
      'getIpos',
    ]),
    ipoIncludedInFilters (ipo) {
      if (!this.includeSpac && this.ipoNameIsSpac(ipo)) {
        return false
      } else if (this.searchString.length > 0 && !ipo.name.includes(this.searchString)) {
        return false
      }
      return true
    },
    ipoNameIsSpac (ipo) {
      return ipo.name.includes('스팩') || ipo.name.includes('기업인수')
    }, 
    selectedRow (ipoId) {
      return (this.selectedIpoId == ipoId) ? 'background-color: lightyellow' : ''
    },
    selectRow (ipoId) {
      this.selectedIpoId = ipoId
    },
    toggleFilterOptions () {
      this.showFilterOptions = !this.showFilterOptions
    },
  },
  mounted () {
    this.getIpos()
  },
}
</script>

<style lang='scss' scoped>
  .ipo-table-container {
    @apply overflow-auto;
    height: calc(100vh - 64px);
  }

</style>
